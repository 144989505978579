import { getImage } from "gatsby-plugin-image"
import { Themed } from "theme-ui"
import { Article, Section } from "../../components/containers"
import { ArticleTitle } from "../../components/headings"
import { Paragraph } from "../../components/text"
import Seo from "../../components/Seo"

import { useCardImage } from "../../hooks/use-card-image"

const CreditsPage = ({ location }) => {
  const { largeCard } = useCardImage()
  const seoCard = getImage(largeCard)
  const pageTitle = "Credits & Acknowledgements"

  return (
    <>
      <Seo pageUrl={location.pathname} pageTitle={pageTitle} image={seoCard} />
      <Article>
        <ArticleTitle title={pageTitle} />
        <Section semantic={false}>
          <Paragraph>
            The Bitter Drop is written by Isidore Bloom; it is licensed under
            Creative Commons BY-NC-SA 4.0 — for a human-readable version of the
            license terms,{" "}
            <Themed.a
              href="http://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1"
              target="_blank"
              rel="license noopener noreferrer"
            >
              see here
            </Themed.a>
            .
          </Paragraph>
          <Paragraph>
            Aster Rowan provides invaluable geomancy assistance.
          </Paragraph>
          <Paragraph>Site design, layout and code by Isidore Bloom. </Paragraph>
          <Paragraph>
            All final graphics designed by Isidore Bloom; favicon made with stock{" "}
            <Themed.a href="http://www.freepik.com">
              designed by Freepik
            </Themed.a>
            ; logo and title graphics made using stock resources from{" "}
            <Themed.a href="https://creativemarket.com/DigitalCurios">
              Digital Curio
            </Themed.a>
            .
          </Paragraph>
        </Section>
      </Article>
    </>
  )
}

export default CreditsPage
